import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import kebabCase from 'lodash/kebabCase';
import { Content, Header, Layout, SectionTitle, SEO, Subline } from '../components';
import SiteConfig from '../../config/SiteConfig';
import '../utils/prismjs-theme.css';
import PathContext from '../models/PathContext';
import Post from '../models/Post';
import { HeaderItem } from '../components/Header';
import { SectionSubtitle } from '../components/SectionSubtitle';

const PostContent = styled.div`
  margin-top: 0rem;

  & .fig img, & .pow img {
    display: inline;
  }

  & img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0.6em 0;
  }

  & p {
    line-height: 1.2;
  }
`;

interface Props {
  data: {
    markdownRemark: Post;
  };
  pathContext: PathContext;
}

export default class PostPage extends React.PureComponent<Props> {
  public render() {
    const post = this.props.data.markdownRemark;
    return (
      <Layout>
        {post ? (
          <React.Fragment>
            <SEO postPath={post.fields.slug} postNode={post} postSEO />
            <Helmet title={`${post.frontmatter.title} | ${SiteConfig.siteTitle}`} />
            <Header banner={post.frontmatter.fbimage} selected={HeaderItem.Writing}>
              <SectionTitle>{post.frontmatter.title}</SectionTitle>
              {post.frontmatter.subtitle && (
                <SectionSubtitle>{post.frontmatter.subtitle}</SectionSubtitle>
              )}
              <Subline>
                {post.frontmatter.date} &mdash; a {post.timeToRead} minute read on
                <Link to={`/${kebabCase(post.frontmatter.category)}`}> {post.frontmatter.category}</Link>
              </Subline>
            </Header>
            <Content>
              <PostContent dangerouslySetInnerHTML={{ __html: post.html }} />
            </Content>
          </React.Fragment>
        ) : null}
      </Layout>
    );
  }
}

export const postQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      fields {
        path
      }
      frontmatter {
        title
        subtitle
        date(formatString: "D MMM YYYY")
        year: date(formatString: "YYYY")
        month: date(formatString: "MM")
        day: date(formatString: "DD")
        category
        tags
        fbimage
      }
      timeToRead
    }
  }
`;
